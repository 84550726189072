
.compact-table .p-datatable-thead > tr > th,
.compact-table .p-datatable-tbody > tr > td {
  padding: 0.5rem 1rem;
  font-size: 13px;
}

.p-datatable.p-datatable-default .p-datatable-tbody > tr.border-warning > td:first-child {
  border-left: 5px solid orange;
}

.dd-table-filter-clearer-wrapper { position:relative; }
.dd-table-filter-clearer-wrapper .dd-table-filter-clearer {
  position: absolute;
  padding: 6px 10px;
  right: 6px;
  cursor: pointer;
  opacity: 0.6;
}
.dd-table-filter-clearer-wrapper .dd-table-filter-clearer:hover {
  opacity: 1;
}

.p-datatable.p-datatable-default .p-datatable-tbody > tr.border-danger > td:first-child {
  border-left: 5px solid red;
}

  .p-paginator {
	background: none;
	font-size: 0.775rem;

	.p-paginator-current {
	  margin-left: auto;
	}

	.p-dropdown { min-width: 100px }
	.p-dropdown .p-dropdown-label {
	  padding: 10px;
	  font-size: 0.775rem;
	}
  }

  .table-header {
	display: flex;
	justify-content: space-between;
  }

  .p-calendar .p-datepicker {
	min-width: 25rem;

	td {
	  font-weight: 400;
	}
  }

  .p-datatable.p-datatable-default {

	font-size: 0.775rem;

	.p-datatable-header {
	  padding: 1rem;
	  text-align: left;
	  font-size: 1.5rem;
	}

	.p-paginator {
	  padding: 1rem;
	}

	.p-datatable-thead > tr > th {
	  text-align: left;
	  border: 0;
	  background: none;
	  padding: 0.5rem;
	  white-space: nowrap;
	}

	tr.cursor-pointer > td {
	  cursor: pointer !important;
	}
	.p-datatable-tbody > tr.row-expanded > td {
	  background: #d7e59b !important;    font-weight: bold;
	}

	.p-datatable-tbody > tr.expanded-row > td {
	  background: #fff !important;
	  border-bottom: 1px solid #c2c0c0;
	}

	.p-datatable-tbody > tr > td {
	  cursor: auto;
	  padding: 0.5rem;
	  border: 0;
	  vertical-align: top;

	}
	.p-datatable-tbody > tr > td > .fa,
	.p-datatable-tbody > tr > td > .far,
	.p-datatable-tbody > tr > td > .fas {
	  color: $primaryDarkerColor
	}
	.p-datatable-tbody>tr:nth-child(2n) > td {
	  background: #ddd;
	}
	.p-dropdown-label:not(.p-placeholder) {
	  text-transform: uppercase;
	}

	&.with-filters {
	  .p-datatable-thead > tr:first-child th {
		padding-bottom: 0px;
	  }
	  .p-datatable-filters > th {
	  	padding-top: 0px;
	  }
	  .p-datatable-filters input {
		background: #fff;
		font-size: 0.675rem;
		padding: 0.475rem 0.575rem;
		min-width: 100%;
		max-width: 100%;
	  }

	  .p-datatable-filters .p-multiselect .p-multiselect-panel .p-multiselect-item {
		padding: 0.5rem;
		border-bottom: 1px solid #dddddd !important;
	  }
	  .p-datatable-filters .p-multiselect .p-multiselect-panel .p-multiselect-items {
		padding: 1px;
		font-size: 0.775rem;
	  }
	  .p-datatable-filters .p-multiselect .p-multiselect-label {
		padding: 0.475rem 0.575rem;
	  }
	  .p-datatable-filters .p-multiselect {
		font-size: 0.675rem;
		padding: 0;
		min-width: 100%;
		max-width: 100%;
		background: #fff;
		margin-top: -4px;
	  }

	  .p-datatable-filters {
		.p-input-icon-left > i.pi-search {
		  font-size: 0.875rem;
		  top: 50%;
		  margin-top: -0.6rem;
		  left: 7px;
		}

		.p-input-icon-left input {
		  padding-left: 1.6rem !important;
		}
	  }
	}
  }


  /* Responsive */
  .p-datatable-default .p-datatable-tbody > tr > td .p-column-title {
	display: none;
  }

.p-table-mobile-filter {
  position: fixed;
  right: 10px;
  top: 65px;
  z-index: +1;

  .p-sidebar-close { color:#fff !important; }
  &.toggler .p-button-raised {
	border: 1px solid #ffffff;
  }
  .row {
	margin-bottom:20px;
  }
  .display-5 {
	margin-bottom: 25px;
  }
  .p-column-filter:not(.p-calendar) {
	background: #fff;
	font-size: 0.675rem;
	padding: 0.475rem 0.575rem;
	min-width: 100%;
	max-width: 100%;
  }
  .p-column-filter-label {
	padding: 0.3rem;
  }
  .p-calendar { width: 100% }
  .p-calendar .p-inputtext {
	background: #fff;
	font-size: 0.675rem;
	padding: 0.475rem 0.575rem;
	min-width: 100%;
	max-width: 100%;
  }
  .p-multiselect .p-multiselect-panel .p-multiselect-item {
	padding: 0.5rem;
	border-bottom: 1px solid #dddddd !important;
  }
  .p-multiselect .p-multiselect-panel .p-multiselect-items {
	padding: 1px;
	font-size: 0.775rem;
  }
  .p-multiselect .p-multiselect-label {
	padding: 0.475rem 0.575rem;    color: black;
  }
  .p-multiselect.p-column-filter {
	font-size: 0.675rem;
	padding: 0;
	min-width: 100%;
	max-width: 100%;
	background: #fff;
	margin-top: -4px;
  }
}

@media screen and (max-width: 768px) {
  .p-datatable.p-datatable-default.with-filters {
	.p-datatable-thead > tr:first-child th {
	  padding-bottom: 5px;
	}
  }
}
/*
@media screen and (max-width: 960px) {
	.p-datatable {
	  &.p-datatable-default {
		.p-datatable-thead > tr > th,
		.p-datatable-tfoot > tr > td {
		  display: none !important;
		}

		.p-datatable-tbody > tr {
		  border-bottom: 1px solid var(--layer-2);

		  > td {
			text-align: left;
			display: block;
			border: 0 none !important;
			width: 100% !important;
			float: left;
			clear: left;
			border: 0 none;

			.p-column-title {
			  padding: .4rem;
			  min-width: 30%;
			  display: inline-block;
			  margin: -.4rem 1rem -.4rem -.4rem;
			  font-weight: bold;
			}

			.p-progressbar {
			  margin-top: .5rem;
			}
		  }
		}
	  }
	}
}
*/
