.p-button {
  color: $buttonTextColor;
  background: $buttonBg;
  border: $buttonBorder;
  padding: $buttonPadding;
  font-size: $fontSize;
  transition: $formElementTransition;
  border-radius: $borderRadius;

  &:enabled:hover {
	background: $buttonHoverBg;
	color: $buttonTextColor;
	border-color: $buttonHoverBorderColor;
  }

  &:enabled:active {
	background: $buttonActiveBg;
	color: $buttonTextColor;
	border-color: $buttonActiveBorderColor;
  }

  &.p-button-outlined {
	background-color: transparent;
	color: $buttonBg;
	border: $outlinedButtonBorder;
	.p-button-label {
	  color: $buttonBg;
	}
	&:enabled:hover {
	  background: rgba($buttonBg, $textButtonHoverBgOpacity);
	  color: $buttonBg;
	  border: $outlinedButtonBorder;
	}

	&:enabled:active {
	  background: rgba($buttonBg, $textButtonActiveBgOpacity);
	  color: $buttonBg;
	  border: $outlinedButtonBorder;
	}
  }

  &.p-button-text {
	background-color: transparent;
	color: $buttonBg;
	border-color: transparent;

	&:enabled:hover {
	  background: rgba($buttonBg, $textButtonHoverBgOpacity);
	  color: $buttonBg;
	  border-color: transparent;
	}

	&:enabled:active {
	  background: rgba($buttonBg, $textButtonActiveBgOpacity);
	  color: $buttonBg;
	  border-color: transparent;
	}

	&.p-button-plain {
	  color: $plainButtonTextColor;

	  &:enabled:hover {
		background: $plainButtonHoverBgColor;
		color: $plainButtonTextColor;
	  }

	  &:enabled:active {
		background: $plainButtonActiveBgColor;
		color: $plainButtonTextColor;
	  }
	}
  }

  &:focus {
	@include focused();
  }

  .p-button-icon-left {
	margin-right: $inlineSpacing;
  }

  .p-button-icon-right {
	margin-left: $inlineSpacing;
  }

  .p-button-icon-bottom {
	margin-top: $inlineSpacing;
  }

  .p-button-icon-top {
	margin-bottom: $inlineSpacing;
  }

  .p-badge {
	margin-left: $inlineSpacing;
	min-width: $fontSize;
	height: $fontSize;
	line-height: $fontSize;
	color: $primaryColor;
	background-color: $primaryTextColor;
  }

  &.p-button-raised {
	box-shadow: $raisedButtonShadow;
  }

  &.p-button-rounded {
	border-radius: $roundedButtonBorderRadius;
  }

  &.p-button-icon-only {
	width: $buttonIconOnlyWidth;
	padding: $buttonIconOnlyPadding;

	.p-button-icon-left,
	.p-button-icon-right {
	  margin: 0;
	}

	&.p-button-rounded {
	  border-radius: 50%;
	  height: $buttonIconOnlyWidth;
	}
  }

  &.p-button-sm {
	@include scaledFontSize($fontSize, $scaleSM);
	@include scaledPadding($buttonPadding, $scaleSM);

	.p-button-icon {
	  @include scaledFontSize($primeIconFontSize, $scaleSM);
	}
  }

  &.p-button-lg {
	@include scaledFontSize($fontSize, $scaleLG);
	@include scaledPadding($buttonPadding, $scaleLG);

	.p-button-icon {
	  @include scaledFontSize($primeIconFontSize, $scaleLG);
	}
  }
}

.p-fluid {
  .p-button {
	width: 100%;
  }

  .p-button-icon-only {
	width: $buttonIconOnlyWidth;
  }

  .p-buttonset {
	display: flex;

	.p-button {
	  flex: 1;
	}
  }
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: $secondaryButtonTextColor;
  background: $secondaryButtonBg;
  border: $secondaryButtonBorder;

  &:enabled:hover {
	background: $secondaryButtonHoverBg;
	color: $secondaryButtonTextHoverColor;
	border-color: $secondaryButtonHoverBorderColor;
  }

  &:enabled:focus {
	box-shadow: $secondaryButtonFocusShadow;
  }

  &:enabled:active {
	background: $secondaryButtonActiveBg;
	color: $secondaryButtonTextActiveColor;
	border-color: $secondaryButtonActiveBorderColor;
  }

  &.p-button-outlined {
	background-color: transparent;
	color: $secondaryButtonBg;
	border: $outlinedButtonBorder;

	&:enabled:hover {
	  background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
	  color: $secondaryButtonBg;
	  border: $outlinedButtonBorder;
	}

	&:enabled:active {
	  background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
	  color: $secondaryButtonBg;
	  border: $outlinedButtonBorder;
	}
  }

  &.p-button-text {
	background-color: transparent;
	color: $secondaryButtonBg;
	border-color: transparent;

	&:enabled:hover {
	  background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
	  border-color: transparent;
	  color: $secondaryButtonBg;
	}

	&:enabled:active {
	  background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
	  border-color: transparent;
	  color: $secondaryButtonBg;
	}
  }
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  color: $infoButtonTextColor;
  background: $infoButtonBg;
  border: $infoButtonBorder;

  &:enabled:hover {
	background: $infoButtonHoverBg;
	color: $infoButtonTextHoverColor;
	border-color: $infoButtonHoverBorderColor;
  }

  &:enabled:focus {
	box-shadow: $infoButtonFocusShadow;
  }

  &:enabled:active {
	background: $infoButtonActiveBg;
	color: $infoButtonTextActiveColor;
	border-color: $infoButtonActiveBorderColor;
  }

  &.p-button-outlined {
	background-color: transparent;
	color: $infoButtonBg;
	border: $outlinedButtonBorder;

	&:enabled:hover {
	  background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
	  color: $infoButtonBg;
	  border: $outlinedButtonBorder;
	}

	&:enabled:active {
	  background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
	  color: $infoButtonBg;
	  border: $outlinedButtonBorder;
	}
  }

  &.p-button-text {
	background-color: transparent;
	color: $infoButtonBg;
	border-color: transparent;

	&:enabled:hover {
	  background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
	  border-color: transparent;
	  color: $infoButtonBg;
	}

	&:enabled:active {
	  background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
	  border-color: transparent;
	  color: $infoButtonBg;
	}
  }
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  color: $successButtonTextColor;
  background: $successButtonBg;
  border: $successButtonBorder;

  &:enabled:hover {
	background: $successButtonHoverBg;
	color: $successButtonTextHoverColor;
	border-color: $successButtonHoverBorderColor;
  }

  &:enabled:focus {
	box-shadow: $successButtonFocusShadow;
  }

  &:enabled:active {
	background: $successButtonActiveBg;
	color: $successButtonTextActiveColor;
	border-color: $successButtonActiveBorderColor;
  }

  &.p-button-outlined {
	background-color: transparent;
	color: $successButtonBg;
	border: $outlinedButtonBorder;

	&:enabled:hover {
	  background: rgba($successButtonBg, $textButtonHoverBgOpacity);
	  color: $successButtonBg;
	  border: $outlinedButtonBorder;
	}

	&:enabled:active {
	  background: rgba($successButtonBg, $textButtonActiveBgOpacity);
	  color: $successButtonBg;
	  border: $outlinedButtonBorder;
	}
  }

  &.p-button-text {
	background-color: transparent;
	color: $successButtonBg;
	border-color: transparent;

	&:enabled:hover {
	  background: rgba($successButtonBg, $textButtonHoverBgOpacity);
	  border-color: transparent;
	  color: $successButtonBg;
	}

	&:enabled:active {
	  background: rgba($successButtonBg, $textButtonActiveBgOpacity);
	  border-color: transparent;
	  color: $successButtonBg;
	}
  }
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  color: $warningButtonTextColor;
  background: $warningButtonBg;
  border: $warningButtonBorder;

  &:enabled:hover {
	background: $warningButtonHoverBg;
	color: $warningButtonTextHoverColor;
	border-color: $warningButtonHoverBorderColor;
  }

  &:enabled:focus {
	box-shadow: $warningButtonFocusShadow;
  }

  &:enabled:active {
	background: $warningButtonActiveBg;
	color: $warningButtonTextActiveColor;
	border-color: $warningButtonActiveBorderColor;
  }

  &.p-button-outlined {
	background-color: transparent;
	color: $warningButtonBg;
	border: $outlinedButtonBorder;

	&:enabled:hover {
	  background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
	  color: $warningButtonBg;
	  border: $outlinedButtonBorder;
	}

	&:enabled:active {
	  background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
	  color: $warningButtonBg;
	  border: $outlinedButtonBorder;
	}
  }

  &.p-button-text {
	background-color: transparent;
	color: $warningButtonBg;
	border-color: transparent;

	&:enabled:hover {
	  background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
	  border-color: transparent;
	  color: $warningButtonBg;
	}

	&:enabled:active {
	  background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
	  border-color: transparent;
	  color: $warningButtonBg;
	}
  }
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  color: $helpButtonTextColor;
  background: $helpButtonBg;
  border: $helpButtonBorder;

  &:enabled:hover {
	background: $helpButtonHoverBg;
	color: $helpButtonTextHoverColor;
	border-color: $helpButtonHoverBorderColor;
  }

  &:enabled:focus {
	box-shadow: $helpButtonFocusShadow;
  }

  &:enabled:active {
	background: $helpButtonActiveBg;
	color: $helpButtonTextActiveColor;
	border-color: $helpButtonActiveBorderColor;
  }

  &.p-button-outlined {
	background-color: transparent;
	color: $helpButtonBg;
	border: $outlinedButtonBorder;

	&:enabled:hover {
	  background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
	  color: $helpButtonBg;
	  border: $outlinedButtonBorder;
	}

	&:enabled:active {
	  background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
	  color: $helpButtonBg;
	  border: $outlinedButtonBorder;
	}
  }

  &.p-button-text {
	background-color: transparent;
	color: $helpButtonBg;
	border-color: transparent;

	&:enabled:hover {
	  background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
	  border-color: transparent;
	  color: $helpButtonBg;
	}

	&:enabled:active {
	  background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
	  border-color: transparent;
	  color: $helpButtonBg;
	}
  }
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: $dangerButtonTextColor;
  background: $dangerButtonBg;
  border: $dangerButtonBorder;

  &:enabled:hover {
	background: $dangerButtonHoverBg;
	color: $dangerButtonTextHoverColor;
	border-color: $dangerButtonHoverBorderColor;
  }

  &:enabled:focus {
	box-shadow: $dangerButtonFocusShadow;
  }

  &:enabled:active {
	background: $dangerButtonActiveBg;
	color: $dangerButtonTextActiveColor;
	border-color: $dangerButtonActiveBorderColor;
  }

  &.p-button-outlined {
	background-color: transparent;
	color: $dangerButtonBg;
	border: $outlinedButtonBorder;

	&:enabled:hover {
	  background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
	  color: $dangerButtonBg;
	  border: $outlinedButtonBorder;
	}

	&:enabled:active {
	  background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
	  color: $dangerButtonBg;
	  border: $outlinedButtonBorder;
	}
  }

  &.p-button-text {
	background-color: transparent;
	color: $dangerButtonBg;
	border-color: transparent;

	&:enabled:hover {
	  background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
	  border-color: transparent;
	  color: $dangerButtonBg;
	}

	&:enabled:active {
	  background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
	  border-color: transparent;
	  color: $dangerButtonBg;
	}
  }
}

.p-button.p-button-link {
  color: $linkButtonColor;
  background: transparent;
  border: transparent;

  &:enabled:hover {
	background: transparent;
	color: $linkButtonHoverColor;
	border-color: transparent;

	.p-button-label {
	  text-decoration: $linkButtonTextHoverDecoration;
	}
  }

  &:enabled:focus {
	background: transparent;
	box-shadow: $linkButtonFocusShadow;
	border-color: transparent;
  }

  &:enabled:active {
	background: transparent;
	color: $linkButtonColor;
	border-color: transparent;
  }
}

.p-selectbutton {
  .p-button {
	background: $toggleButtonBg;
	border: $toggleButtonBorder;
	color: $toggleButtonTextColor;
	transition: $formElementTransition;

	.p-button-icon-left,
	.p-button-icon-right {
	  color: $toggleButtonIconColor;
	}

	&:not(.p-disabled):not(.p-highlight):hover {
	  background: $toggleButtonHoverBg;
	  border-color: $toggleButtonHoverBorderColor;
	  color: $toggleButtonTextHoverColor;

	  .p-button-icon-left,
	  .p-button-icon-right {
		color: $toggleButtonIconHoverColor;
	  }
	}

	&.p-highlight {
	  background: $toggleButtonActiveBg;
	  border-color: $toggleButtonActiveBorderColor;
	  color: $toggleButtonTextActiveColor;

	  .p-button-icon-left,
	  .p-button-icon-right {
		color: $toggleButtonIconActiveColor;
	  }

	  &:hover {
		background: $toggleButtonActiveHoverBg;
		border-color: $toggleButtonActiveHoverBorderColor;
		color: $toggleButtonTextActiveHoverColor;

		.p-button-icon-left,
		.p-button-icon-right {
		  color: $toggleButtonIconActiveHoverColor;
		}
	  }
	}
  }

  &.p-error > .p-button,
  &.p-invalid > .p-button {
	@include invalid-input();
  }
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  @include invalid-input();
}
