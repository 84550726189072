.wrapper {
  width: 200px;
  height: 60px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: +2;
  transform: translate(-50%, -50%);
}
.wrapper-overlay {
  background: #0000008f;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: +1;
  bottom: 0;
  right: 0;
  left: 0;
}

.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: $primaryColor;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
  background-color: $primaryDarkerColor;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
  background-color: $primaryDarkColor;
}

.shadow-loader {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow-loader 0.5s alternate infinite ease;
}

@keyframes shadow-loader {
  0% {
    transform: scaleX(1.5);
  }
  20% {
    opacity: 0.8;
  }
  40% {
    transform: scaleX(1);
    opacity: 0.2;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.05;
  }
}

.shadow-loader:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.shadow-loader:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.wrapper span {
  position: absolute;
  top: 90px;
  font-size: 20px;
  letter-spacing: 5px;
  color: #fff;
  text-align: center;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 60%);
  /* left: 29%; */
  right: auto;
  display: block;
}
