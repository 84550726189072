// Theme Specific Variables
$primaryColor: #EE2763;
$primaryDarkColor: #d51e55;
$primaryDarkerColor: #b01a47;
$primaryTextColor: #343434;
$primaryGrey: #f2f2f2;

$navBarHeight: 54px;
$navBarBg: #F7F5F3;
$bodyBg: #F7F5F3;

// Mandatory Designer Variables
@import "./variables/general";
@import "./variables/form";
@import "./variables/button";
@import "./variables/panel";
@import "./variables/_data";
@import "./variables/_overlay";
@import "./variables/_message";
@import "./variables/_menu";
@import "./variables/_media";
@import "./variables/_misc";

:root {
  --surface-a: #ffffff;
  --surface-b: #f2f2f2;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #383a3c;
  --text-color-secondary: #6c757d;
  --primary-color: $primaryColor;
  --primary-color-text: $primaryTextColor;
  --font-family: 'Open Sans', sans-serif !important;
}
