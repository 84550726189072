/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/mytheme/bootstrap-variables.scss";
@import "theme/mytheme/mixins.scss";
// 3rd party libraries
//@import "~bootstrap/dist/css/bootstrap-reboot.css";
//@import "~bootstrap/dist/css/bootstrap-grid.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
// PrimeNG
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/themes/saga-blue/theme.css";
@import "theme/mytheme/theme.scss";
@import "../node_modules/primeng/resources/primeng.min.css";
