// Configuration for the font-face of the theme, defaults to the system font so left as blank
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=League+Spartan:wght@300;400;500;700&family=Open+Sans&display=swap');
@font-face {
  font-family: 'Feltpen';
  src: url('/assets/fonts/Linotype_Feltpen_Medium.woff') format('woff');
}
/*
font-family: 'Bree Serif', serif;
font-family: 'League Spartan', sans-serif;
font-family: 'Open Sans', sans-serif;
 */

body {
  font-family: 'Open Sans', sans-serif;font-weight: 400;
  font-size: $fontSize;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: 'League Spartan', sans-serif;font-weight: 700;
}
.p-component {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
}
.p-button, .p-button > span:not(.p-button-icon), .p-button > .p-button-label, .p-tag {
  font-family: 'League Spartan', sans-serif;font-weight: 500;
}
.display-6 {
  font-size: 0.875rem;
  font-weight: 400;
}
.heading-font {
  font-family: 'League Spartan', sans-serif;
}
.alt-font {
  font-family: 'Bree Serif', serif;
}
.font-feltpen {
  font-family: 'Feltpen', serif;
}
