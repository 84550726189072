/*
 * Application global variables.
 */

// Set Font Awesome font path
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

// ---------------------------------------------------------------------------
// Bootstrap variables
//
// Override Bootstrap variables here to suite your theme.
// Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss

//
// Color system
//

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #b01a47;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #c79704;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$akse: #EE2763;

$theme-colors: (
		primary: $akse,
		secondary: $gray-600,
		success: $green,
		info: $cyan,
		warning: $yellow,
		danger: $red,
		light: $gray-100,
		dark: $gray-800,
);
$min-contrast-ratio:   3 !default;

// Use Bootstrap defaults for other variables, imported here so we can access all app variables in one place when used
// in components.
//@import "~bootstrap/scss/_functions";
//@import "~bootstrap/scss/_variables";
