.p-card.p-card-slim {
  background: $panelContentBg;
  color: $panelContentTextColor;
  box-shadow: $cardShadow;
  border-radius: $borderRadius;

  .p-card-body {
	padding: 0;
  }

  .p-card-title {
	font-size: 0.95rem;
	font-weight: normal;
	margin-bottom: $inlineSpacing;
  }

  .p-card-subtitle {
	font-weight: $cardSubTitleFontWeight;
	margin-bottom: $inlineSpacing;
	color: $cardSubTitleColor;
  }

  .p-card-content {
	padding: 0 0.8rem;
  }

  .p-card-footer {
	padding: $cardFooterPadding;
  }
}
