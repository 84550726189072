//.course-grid-item { margin-bottom: .5rem;min-height: 170px;display: flex;flex-direction: column;justify-content: space-between; }
.course-grid-item { margin-bottom: .5rem;height:100%;display: flex;flex-direction: column;justify-content: space-between; }
.course-grid-item .p-card-header { padding:0.4rem 1rem .1rem 1rem; }
.course-grid-item .p-card-header p-header { display: flex;flex-direction: column-reverse;justify-content: space-between; }
.course-grid-item .p-card-header .p-card-title { font-size: 0.9rem;font-weight: 500;margin-bottom: 0;letter-spacing: -0.2px;    margin-top: 6px; }
.course-grid-item .p-card-body { padding: 0 1rem .5rem 1rem }
.course-grid-item .p-card-body .p-card-content { padding: 0; }
.course-grid-item .p-card-body .p-card-content .course-ingress { font-size: 0.75rem;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;max-height: 53px; }
.course-grid-item .p-card-body .p-card-footer { padding: 0 0;font-size: 0.75rem }
.course-grid-item .p-tag { color: #fff;font-size: 0.675rem;padding: 0.1rem 0.3rem;font-weight: 400;float:left;margin: 0 2px 2px 0; }


.course-list-items .list-day { font-size: 1.7rem;font-weight: bold;display: block;line-height: 1;padding-top: 2px; }
.course-list-items .list-month { display: block;font-size: 0.9rem;text-transform: uppercase; }
.course-list-items .p-tag { padding-top: 0;padding-bottom: 0;margin-right: 0.2rem }
.course-list-items .list-title { font-size: .9rem;font-weight: 400;margin-bottom: 0.2rem; }
.course-list-items .list-spots { display: inline-block; }
.course-list-items .p-card-content { padding:0; }
.course-list-items .p-card-body { padding:0; }
.course-list-items .row { padding:6px 0;margin: 0 }
.course-list-items .row:not(:first-child) { border-top: 1px solid #e5e5e5; }
.course-list-items .list-left { border-right: 1px solid #e5e5e5;width:15%; }
.course-list-items .list-right { padding: 0 15px;width: 84%; }
