@import "_variables";

.project-perfekt, .project-reform {
  width:100%;height: 100%;
}

@import "_fonts";
//@import "../designer/_components";
@import "_components/loader.component";
@import "_components/course.grid.component";
@import "_extensions";
@import "_tables";
@import "_cards";
@import "_buttons";
@import "../../../node_modules/ngx-ui-switch/ui-switch.component.scss";
//@import '../../../../node_modules/bootstrap/dist/css/bootstrap';

/**
 * Mat sidebar/Drawer & Menu
 */
mat-sidenav-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $bodyBg;
  min-height: 100%;
  height: 100%;

  .mat-drawer-backdrop {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: absolute;
	display: block;
	z-index: 3;
	visibility: hidden;
  }

  .mat-drawer-transition .mat-drawer-backdrop {
	transition-duration: 400ms;
	transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
	transition-property: background-color, visibility;
  }

  .mat-drawer-backdrop.mat-drawer-shown {
	background-color: rgba(0, 0, 0, 0.6);
	visibility: visible;
  }

  app-menu {
	display: flex;
	flex-direction: column;
	height: 100%;
  }

  .p-panelmenu-panel .font-weight-normal a {
	font-size: 13px;
  }

  @media (max-width: 374.98px) {
	mat-sidenav {
	  width: 100% !important;
	}
  }
  @media (min-width: 375px) {
	mat-sidenav {
	  width: 60% !important;
	}
  }

  @media (min-width: 768px) {
	mat-sidenav {
	  width: 40% !important;
	}
  }

  @media (min-width: 992px) {
	mat-sidenav {
	  width: 250px !important;
	}
  }

  @media (min-width: 1200px) {
	mat-sidenav {
	  width: 250px !important;
	}
  }

  mat-sidenav {
	background: #f6f6f6;
  }

  @media (min-height: 700px) {
	mat-sidenav .menu-container {
	  padding-bottom: 25vh;
	}
  }

  .sidenav-header {
	position: absolute;
	right: 0;
	height: $navBarHeight;
	display: flex;
	align-items: center;
	z-index: +1;
  }
}

.p-menu {
  padding:0;
}

.p-selectbutton.p-buttonset.p-button-tags {
  display: flex;
  flex-wrap: wrap;

  &.center {
	justify-content: center;
  }

  .p-button {
	padding: 0.4rem 0.6rem;
	font-size: 0.875rem;
	margin:1px;
	border-radius: 0.25rem !important;
	border: 1px solid #ced4da !important;

	&.p-highlight {
	  background-color: $primaryColor;
	  color:#fff;
	}
  }
}

.p-dropdown-sm {
  .p-dropdown-label {
	font-size: 0.875rem;
	padding: 0.2rem 0.5rem;
  }
}

.faq-page {
  .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
	justify-content: start;
  }
}
